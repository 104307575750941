import React, { useState } from 'react';
import styled from 'styled-components';
import { useIsDarkMode } from '../../state/user/hooks';
import Icon from './Icon';
import 'bootstrap-icons/font/bootstrap-icons.css';

// 主容器
const IntroWrapper = styled.div`
  max-width: 420px;
  width: 100%;
  height: 695px;
  max-height: 695px;
  background: ${({ theme }) => theme.bg1};
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

// 暗黑模式颜色
const lightModeColors = {
  text1: '#000000',
  bg2: '#FFFFFF',
  gradient: 'linear-gradient(to right, #ff9ab2 0%, #ff9ab2 60%, rgba(255, 154, 178, 0.5) 100%)',
};

const darkModeColors = {
  text1: '#FFFFFF',
  bg2: '#000000',
  gradient: 'white',
};


// 标题头部
const IntroHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

// 新token标签
const NewTokenLabel = styled.span`
  font-weight: bold;
  font-size: 1.2rem;
  background: linear-gradient(
    to right,
    #ff9ab2 0%,
    #ff9ab2 60%,
    rgba(255, 154, 178, 0.5) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
`;

// 代币信息容器
const TokenInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

// 代币名称
const TokenName = styled.h2`
  font-weight: bold;
  font-size: 1.2rem;
  background: linear-gradient(
    to right,
    #ff9ab2 0%,
    #ff9ab2 60%,
    rgba(255, 154, 178, 0.5) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
`;

// 代币地址容器
const TokenAddressContainer = styled.div`
  display: flex;
  align-items: center;
`;

// 代币地址
const TokenAddress = styled.span`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.text2};
  margin-right: 0.5rem;
`;

// 复制按钮
const CopyButton = styled.button`
  background: none;
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 0.8rem;
  cursor: pointer;
  color: ${({ theme }) => theme.text2};

  &:hover {
    background: ${({ theme }) => theme.bg2};
  }
`;
// 分割线
const Divider = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.bg3};
  margin: 1rem 0;
`;

// 内容容器
const IntroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.5;
  color: ${({ theme }) => theme.text1};

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

// 内容
const IntroText = styled.p`
  margin-bottom: 1rem;
`;

// 页脚
const Footer = styled.div`
  margin-top: auto;
  padding-top: 1rem;
`;
// 页脚分割线
const FooterDivider = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.bg3};
  margin-bottom: 1rem;
`;

// 社交链接
const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

// 字体/暗黑模式
const SocialText = styled.span<{ isDarkMode: boolean }>`
  font-weight: bold;
  font-size: 1.0rem;
  color: ${({ isDarkMode }) => isDarkMode ? darkModeColors.text1 : lightModeColors.text1};
  text-shadow: 
    -1px -1px 0 ${({ isDarkMode }) => isDarkMode ? darkModeColors.bg2 : lightModeColors.bg2},
    1px -1px 0 ${({ isDarkMode }) => isDarkMode ? darkModeColors.bg2 : lightModeColors.bg2},
    -1px 1px 0 ${({ isDarkMode }) => isDarkMode ? darkModeColors.bg2 : lightModeColors.bg2},
    1px 1px 0 ${({ isDarkMode }) => isDarkMode ? darkModeColors.bg2 : lightModeColors.bg2},
    -2px 0 0 ${({ isDarkMode }) => isDarkMode ? darkModeColors.bg2 : lightModeColors.bg2},
    2px 0 0 ${({ isDarkMode }) => isDarkMode ? darkModeColors.bg2 : lightModeColors.bg2},
    0 -2px 0 ${({ isDarkMode }) => isDarkMode ? darkModeColors.bg2 : lightModeColors.bg2},
    0 2px 0 ${({ isDarkMode }) => isDarkMode ? darkModeColors.bg2 : lightModeColors.bg2};
`;

// 图标/暗黑模式
const SocialLink = styled.a<{ isDarkMode: boolean }>`
  font-size: 1.5rem;
  background: ${({ isDarkMode }) => isDarkMode ? darkModeColors.gradient : lightModeColors.gradient};
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  
  &:hover {
    opacity: 0.8;
  }
`;

const Intro: React.FC = () => {
  const [copySuccess, setCopySuccess] = useState(false);
  const tokenAddress = "0x12345678979878979878979878979987899898"; 
  const isDarkMode = useIsDarkMode();

  const truncateAddress = (address: string) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  const copyAddress = () => {
    navigator.clipboard.writeText(tokenAddress)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      })
      .catch(err => console.error('复制失败: ', err));
  };

  return (
    <IntroWrapper>
      <IntroHeader>
        <Icon />
        <NewTokenLabel>NEW TOKEN</NewTokenLabel>
      </IntroHeader>
      <TokenInfoContainer>
        <TokenName>展播鸡鸡</TokenName>
        <TokenAddressContainer>
          <TokenAddress>{truncateAddress(tokenAddress)}</TokenAddress>
          <CopyButton onClick={copyAddress}>
            {copySuccess ? '复制成功' : '复制'}
          </CopyButton>
        </TokenAddressContainer>
      </TokenInfoContainer>
      <Divider />
      <IntroContent>
        <SocialText isDarkMode={isDarkMode}>项目信息：</SocialText>
        <IntroText>
        🐔 震撼登场! 展播鸡鸡来啦!🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 
        </IntroText>
        <IntroText>
        🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 
        </IntroText>
        <IntroText>
        🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 🐔 
        </IntroText>
      </IntroContent>
      <Footer>
        <FooterDivider />
        <SocialLinks>
          <SocialText isDarkMode={isDarkMode}>官方社区：</SocialText>
          <SocialLink isDarkMode={isDarkMode} href="https://t.me/" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-telegram"></i>
          </SocialLink>
          <SocialLink isDarkMode={isDarkMode} href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-twitter"></i>
          </SocialLink>
        </SocialLinks>
      </Footer>
    </IntroWrapper>
  );
};

export default Intro;